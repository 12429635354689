import Icon from "../../../types/iconsax";
import CustomModal from "../../../components/CustomModal";
import { useState } from "react";
import CustomListBox from "../../../components/CustomListBox";
import CustomChip from "../../../components/CustomChip";
import IconClose from "../../../components/icons/IconClose";
import CustomComboBox from "../../../components/CustomComboBox";
import { useRoomsQuery } from "../../../requests/room";
import toast from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";
import { useUpdateCompanyMutation } from "../../../requests/company/useUpdateCompanyMutation";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  buildingId: string;
  companyId: string;
  name: string;
  rooms: { _id: string; dong: string; ho: string; floor: string }[];
  type?: "office" | "store" | "residential" | "etc";
};

type RoomInfo = {
  _id: string;
  dong: string;
  floor: string;
  ho: string;
  roomType?: "office" | "store" | "residential" | "etc";
  owners?: Array<{
    userId: string;
    name: string;
    phoneNumber: string;
  }>;
  companyInfo?: {
    name: string;
    isCompany: boolean;
  };
};

const ROOM_TYPE = [
  { name: "입주사 유형", value: "" },
  { name: "사무", value: "office" },
  { name: "상가", value: "store" },
  { name: "주거", value: "residential" },
  { name: "기타", value: "etc" },
];

export default function CompanyModifyRoomsModal({ isOpen, onClose, buildingId, companyId, name, rooms, type }: Props) {
  const [roomType, setRoomType] = useState(type ?? "");

  const { mutateAsync: updateCompany } = useUpdateCompanyMutation();

  const { data: roomData } = useRoomsQuery(
    {
      buildingId,
      roomType: type || undefined,
      searchText: "",
      dong: "",
      floor: "",
      page: 1,
    },
    !!buildingId,
  );

  const [roomArr, setRoomArr] = useState<RoomInfo[]>(rooms);

  const handleSelectRoom = (data: { name: string; value: string } | never) => {
    if (!data) return;
    if (data.value !== "") {
      if (roomArr.some((item: { _id: string }) => item._id === data.value))
        return toast(<CustomToast title="호실 중복 설정" description="이미 추가한 호실입니다. 다시 확인해주세요." />);

      const room = roomData?.data.rooms.find((item) => item._id === data.value);
      if (room) {
        setRoomArr([...roomArr, room]);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title="회사 정보 수정"
      exit={true}
      primaryButtonText="저장하기"
      onPrimaryButtonClick={() =>
        toast.promise(updateCompany({ companyId, rooms: roomArr.map((room) => room._id) }), {
          success: () => {
            onClose();
            return <CustomToast iconType="check" title={`'${name || "-"}'의 권한이 변경되었습니다.`} />;
          },
          error: () => {
            onClose();
            return <CustomToast iconType="error" title="권한 변경 중 에러가 발생하였습니다." />;
          },
          loading: <CustomToast iconType="loading" title="Loading" />,
        })
      }
      primaryButtonDisable={
        (roomArr.every((v) => rooms.some((room) => room._id === v._id)) && roomArr.length === rooms.length) ||
        roomArr.length < 1
      }
      neutralButtonText="취소"
      onNeutralButtonClick={onClose}
    >
      <div className="flex w-[700px] flex-col gap-4 p-6 pt-3">
        <div className="flex flex-col gap-1">
          <div className="flex gap-1 text-sm font-semibold">
            <span className="text-gray-700">입주한 호실</span>
            <span className="text-blue-600">*</span>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex h-12 gap-4">
              <CustomListBox
                themeSize="md"
                data={ROOM_TYPE}
                getValue={() => roomType}
                onChange={setRoomType}
                disabled={true}
              />
              <CustomComboBox
                themeSize="md"
                data={[
                  { name: "호실", value: "" },
                  ...(roomData?.data.rooms.map((item) => ({
                    name: `${item.dong}동 ${item.ho}호`,
                    value: item._id.toString(),
                    disabled: item.companyInfo.isCompany,
                    statusText: item.companyInfo.isCompany ? "입주중" : undefined,
                  })) || []),
                ]}
                getValue={() => ""}
                onChange={handleSelectRoom}
                disabled={roomType === ""}
              />
            </div>
            <div className="flex gap-2">
              <Icon.InfoCircle size={20} color="#3B82F6" />
              <span className="text-sm font-medium text-blue-500">
                입주사 유형(사무, 상가, 주거)을 기준으로 같은 호실용도의 호실만 등록할 수 있습니다.
              </span>
            </div>
            <div className="flex flex-wrap gap-4 border-t border-gray-200 py-4">
              {roomArr.map((item: { _id: string; dong: string; ho: string }) => (
                <CustomChip
                  key={item._id}
                  size="md"
                  designSchema="blue"
                  label={`${item.dong}동 ${item.ho}호`}
                  className="text-gray-700"
                  lastIconElement={
                    <IconClose
                      className="size-3"
                      fill="#2563EB"
                      onClick={() => setRoomArr((prev: RoomInfo[]) => prev.filter((v) => v._id !== item._id))}
                    />
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
