import { useFieldArray, useForm } from "react-hook-form";
import Button from "../../../components/button/Button";
import CustomChip from "../../../components/CustomChip";
import CustomComboBox from "../../../components/CustomComboBox";
import CustomListBox from "../../../components/CustomListBox";
import CustomRadioGroup from "../../../components/CustomRadioGroup";
import Input from "../../../components/Input/Input";
import Icon from "../../../types/iconsax";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../../App";
import { useRoomsQuery } from "../../../requests/room";
import toast from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";
import IconClose from "../../../components/icons/IconClose";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { useCreateCompanyMutation } from "../../../requests/company/useCreateCompanyMutation";
import { useNavigate } from "react-router-dom";
import CustomCheckBox from "../../../components/CustomCheckBox";
import useUserAuth from "../../../components/hooks/useUserAuth";
import { uploadAllFiles } from "../commons";
import IconPlus from "../../../components/icons/IconPlus";
import { useInviteCompanyMember } from "../../../requests/company/useInviteCompanyMember";

type RoomInfo = {
  _id: string;
  dong: string;
  floor: string;
  ho: string;
  roomType: "office" | "store" | "residential" | "etc";
  owners: Array<{
    userId: string;
    name: string;
    phoneNumber: string;
  }>;
  companyInfo: {
    name: string;
    isCompany: boolean;
  };
};

const ROOM_TYPE = [
  { name: "입주사 유형", value: "" },
  { name: "사무", value: "office" },
  { name: "상가", value: "store" },
  { name: "주거", value: "residential" },
  { name: "기타", value: "etc" },
];

const MOVE_IN_TYPE = [
  { name: "월세", value: "monthly_rent" },
  { name: "소유", value: "own_house" },
  { name: "기타", value: "etc" },
];

interface FormData {
  name: string;
  members: {
    name: string;
    phoneNumber: string;
  };
  companyRegistrationNumber?: string;
  taxEmail?: string;
  ceoName?: string;
  phoneNumber?: string;
  fax?: string;
  contractFiles?: {
    directory: string | File;
    name: string;
  }[];
  businessFiles?: {
    directory: string | File;
    name: string;
  }[];
}

export default function CreateCompanyPage() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
    control,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      taxEmail: undefined,
      companyRegistrationNumber: undefined,
      fax: undefined,
      contractFiles: undefined,
      businessFiles: undefined,
    },
  });

  const [moveInType, setMoveInType] = useState<string>("");
  const [roomType, setRoomType] = useState("");
  const [roomArr, setRoomArr] = useState<RoomInfo[]>([]);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [isSendInvite, setIsSendInvite] = useState<boolean>(true);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;

  const { buildInfo } = useContext(GlobalContext);

  const { data: roomData } = useRoomsQuery(
    {
      buildingId: buildInfo?._id.toString() || "",
      roomType: (roomType as "office" | "store" | "residential" | "etc") || undefined,
      searchText: "",
      dong: "",
      floor: "",
      page: 1,
    },
    !!buildInfo?._id,
  );
  const { mutateAsync: createCompany } = useCreateCompanyMutation();
  const { mutateAsync: inviteCompanyMember } = useInviteCompanyMember();

  const navigate = useNavigate();

  const auth = useUserAuth("tenant");

  const businessInputRef = useRef<HTMLInputElement>(null);
  const contractInputRef = useRef<HTMLInputElement>(null);

  const ceoName = watch("ceoName") || "";
  const ceoPhoneNumber = watch("phoneNumber") || "";

  useEffect(() => {
    if (enabled) {
      setValue("members.name", ceoName);
      setValue("members.phoneNumber", ceoPhoneNumber);
      trigger("members.name");
      trigger("members.phoneNumber");
    }
  }, [enabled]);

  const handleSelectRoom = (data: { name: string; value: string } | never) => {
    if (!data) return;
    if (data.value !== "") {
      if (roomArr.some((item: { _id: string }) => item._id === data.value))
        return toast(<CustomToast title="호실 중복 설정" description="이미 추가한 호실입니다. 다시 확인해주세요." />);

      const room = roomData?.data.rooms.find((item) => item._id === data.value);
      if (room) {
        setRoomArr([...roomArr, room]);
      }
    }
  };

  const onSubmit = async (data: FormData) => {
    if (data.fax === "") delete data?.fax;

    if (roomArr.length === 0) {
      return toast(<CustomToast title="호실 미설정" description="호실을 설정해주세요." />);
    }

    const contractFiles = await uploadAllFiles(data.contractFiles ?? []);
    const businessFiles = await uploadAllFiles(data.businessFiles ?? []);

    toast.promise(
      createCompany({
        buildingId: buildInfo?._id.toString() || "",
        ...data,
        tenantType: roomType as "office" | "store" | "residential" | "etc",
        rooms: roomArr.map((item: { _id: string }) => item._id),
        ...(moveInType && { moveInType: moveInType as "etc" | "monthly_rent" | "own_house" }),
        ...(startDate && { rentStartDate: startDate }),
        ...(endDate && { rentEndDate: endDate }),
        ...(contractFiles && { salesOrLeaseContractFiles: contractFiles }),
        ...(businessFiles && { businessRegistrationFiles: businessFiles }),
      }),
      {
        success: (res) => {
          if (isSendInvite) {
            toast.promise(
              inviteCompanyMember({
                companyId: res.data.id,
                memberId: res.data.members[0]._id,
              }),
              {
                loading: "Loading...",
                success: () => (
                  <CustomToast
                    iconType="check"
                    title={`'${data.members.name}'님께 가입 초대 알림톡이 전송되었습니다.`}
                  />
                ),
                error: () => (
                  <CustomToast
                    iconType="error"
                    title="가입 초대 알림톡 전송 실패"
                    description="잠시 후 다시 시도해주세요."
                  />
                ),
              },
            );
          }

          return <CustomToast iconType="check" title={`'${data.name}'가 등록되었습니다.`} className="w-[25rem]" />;
        },
        error: <CustomToast iconType="error" title="등록 중 에러가 발생하였습니다." />,
        loading: <CustomToast iconType="loading" title="Loading" />,
      },
    );

    navigate("/companies");
  };

  const contractFiles = useFieldArray({ control, name: "contractFiles" });
  const businessFiles = useFieldArray({ control, name: "businessFiles" });

  const { fields: contractFields, remove: removeContractFile, append: appendContractFile } = contractFiles;
  const { fields: businessFields, remove: removeBusinessFile, append: appendBusinessFile } = businessFiles;

  const uploadFile = (e: ChangeEvent<HTMLInputElement>, targetFields: "contract" | "business") => {
    const files = e.target.files;

    if (files === null) return;

    const fields = {
      contract: contractFields,
      business: businessFields,
    }[targetFields];

    const append = {
      contract: appendContractFile,
      business: appendBusinessFile,
    }[targetFields];

    // 파일 개수 제한
    if (fields.length + files.length > 5) {
      return toast(
        <CustomToast iconType="warning" title="파일 업로드" description="파일은 5개까지만 업로드하실 수 있습니다." />,
      );
    }

    // 파일 용량 제한
    if (Array.from(files || []).filter((el) => el.size > 5 * 1024 * 1024).length !== 0) {
      return toast(
        <CustomToast iconType="warning" title="파일 업로드" description="파일 1개당 5MB 이하만 첨부 가능합니다." />,
      );
    }

    Array.from(files).map((el) => append({ directory: el, name: el.name }));

    e.target.value = "";
  };

  return (
    <form className="mx-auto mb-7 flex w-[912px] flex-col gap-6 pb-10" onSubmit={handleSubmit(onSubmit)}>
      <div className="sticky top-0 z-50 flex flex-col gap-2 border-b border-gray-200 bg-white py-6">
        <div className="flex items-center">
          <span className="py-1.5 text-xs font-medium text-gray-500">입주사 관리</span>
          <div className="p-1">
            <Icon.ArrowRight2 variant="Outline" size={16} color="#6B7280" />
          </div>
          <span className="py-1.5 text-xs font-medium text-gray-700">신규 입주사</span>
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex h-9 justify-between">
            <input
              className="w-full text-2xl font-normal leading-9 text-gray-900 placeholder:text-gray-300"
              placeholder="회사명을 입력해주세요"
              {...register("name", {
                required: "회사명은 필수 입력 사항입니다.",
              })}
              onBlur={(e) => setValue("name", e.target.value.trim())}
            />
            <div className="flex gap-3">
              <Button
                designSchema="secondaryGray"
                size="sm"
                buttonText={"취소"}
                onClick={() => navigate("/companies")}
                type="button"
              />
              <Button
                designSchema="primary"
                size="sm"
                buttonText={"입주사 등록하기"}
                type="submit"
                disabled={!(Object.keys(errors).length === 0) || roomArr.length < 1}
              />
            </div>
          </div>
          {errors.name && <p className="text-xs leading-normal text-red-500">{errors.name.message}</p>}
        </div>
      </div>

      <div className="flex flex-col rounded-lg border border-gray-200">
        <div className="flex flex-col gap-1 border-b border-gray-200 px-6 py-4">
          <div className="flex gap-1 text-xl font-semibold leading-8">
            <span className="text-gray-700">호실 정보</span>
          </div>
          <span className="text-sm font-medium text-gray-500">입주사가 사용하고 있는 호실을 모두 등록해주세요.</span>
        </div>
        <div className="flex flex-col gap-6 px-6 py-5">
          <div className="flex flex-col gap-1 py-2">
            <div className="flex gap-1 text-sm font-semibold">
              <span className="text-gray-700">입주한 호실</span>
              <span className="text-blue-600">*</span>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex h-12 gap-4">
                <CustomListBox
                  themeSize="md"
                  data={ROOM_TYPE}
                  getValue={() => roomType}
                  onChange={setRoomType}
                  disabled={roomArr.length > 0}
                />
                <CustomComboBox
                  themeSize="md"
                  data={[
                    { name: "호실", value: "" },
                    ...(roomData?.data.rooms.map((item) => ({
                      name: `${item.dong}동 ${item.ho}호`,
                      value: item._id.toString(),
                      disabled: item.companyInfo.isCompany,
                      statusText: item.companyInfo.isCompany ? "입주중" : undefined,
                    })) || []),
                  ]}
                  getValue={() => ""}
                  onChange={handleSelectRoom}
                  disabled={roomType === ""}
                />
              </div>
              <div className="flex gap-2">
                <Icon.InfoCircle size={20} color="#3B82F6" />
                <span className="text-sm font-medium text-blue-500">
                  입주사 유형(사무, 상가, 주거)을 기준으로 같은 호실용도의 호실만 등록할 수 있습니다.
                </span>
              </div>
              <div className="flex gap-4 border-t border-gray-200 pt-4">
                {roomArr.map((item: { _id: string; dong: string; ho: string }) => (
                  <CustomChip
                    key={item._id}
                    size="md"
                    designSchema="blue"
                    label={`${item.dong}동 ${item.ho}호`}
                    className="text-gray-700"
                    lastIconElement={
                      <IconClose
                        className="size-3"
                        fill="#2563EB"
                        onClick={() => setRoomArr((prev: RoomInfo[]) => prev.filter((v) => v._id !== item._id))}
                      />
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg border border-gray-200">
        <div className="flex flex-col gap-1 border-b border-gray-200 px-6 py-4">
          <div className="flex gap-1 text-xl font-semibold leading-8">
            <span className="text-gray-700">최고관리자 정보</span>
          </div>
          <span className="text-sm font-medium text-gray-500">입주사 등록할 때 필수로 입력해야하는 정보입니다.</span>
        </div>
        <div className="flex flex-col gap-6 px-6 py-5">
          <div className="flex gap-4">
            <div className="flex flex-1 flex-col gap-1">
              <div className="flex gap-1 text-sm font-semibold">
                <span className="text-gray-700">회사 대표자의 이름</span>
              </div>
              <Input
                themeSize="md"
                placeholder="회사 대표자의 이름을 입력해주세요"
                {...register("ceoName")}
                onChange={(e) => {
                  if (enabled) {
                    setValue("members.name", e.target.value.trim());
                    trigger("members.name");
                  }
                }}
                onBlur={(e) => setValue("ceoName", e.target.value.trim())}
              />
            </div>
            <div className="flex flex-1 flex-col gap-1">
              <div className="flex gap-1 text-sm font-semibold">
                <span className="text-gray-700">회사 대표자의 연락처</span>
              </div>
              <Input
                themeSize="md"
                placeholder="회사 대표자의 연락처를 입력해주세요"
                type="number"
                {...register("phoneNumber", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "연락처는 숫자만 입력해주세요.",
                  },
                })}
                onInput={(e) => {
                  if (enabled) {
                    setValue("members.phoneNumber", (e.target as HTMLInputElement).value);
                    trigger("members.phoneNumber");
                  }
                }}
                onBlur={(e) => setValue("phoneNumber", e.target.value.trim())}
              />
              {errors.phoneNumber && (
                <p className="text-xs leading-normal text-red-500">{errors.phoneNumber.message}</p>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <div className="flex flex-1 flex-col gap-1">
                <div className="flex gap-1 text-sm font-semibold">
                  <span className="text-gray-700">최고관리자의 이름</span>
                  <span className="text-blue-600">*</span>
                </div>
                <Input
                  themeSize="md"
                  placeholder="최고관리자 권한을 제안할 분의 이름을 입력해주세요"
                  disabled={enabled}
                  {...register("members.name", {
                    required: "최고관리자의 이름은 필수 입력 사항입니다.",
                  })}
                  onBlur={(e) => setValue("members.name", e.target.value.trim())}
                />
                {errors.members?.name && (
                  <p className="text-xs leading-normal text-red-500">{errors.members.name.message}</p>
                )}
              </div>
              <div className="flex flex-1 flex-col gap-1">
                <div className="flex gap-1 text-sm font-semibold">
                  <span className="text-gray-700">최고관리자의 연락처</span>
                  <span className="text-blue-600">*</span>
                </div>
                <Input
                  themeSize="md"
                  placeholder="최고관리자 권한을 제안할 분의 연락처를 입력해주세요"
                  type="number"
                  disabled={enabled}
                  {...register("members.phoneNumber", {
                    required: "최고관리자의 연락처는 필수 입력 사항입니다.",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "연락처는 숫자만 입력해주세요.",
                    },
                  })}
                  onBlur={(e) => setValue("members.phoneNumber", e.target.value.trim())}
                />
                {errors.members?.phoneNumber?.message && (
                  <p className="text-xs leading-normal text-red-500">{errors.members.phoneNumber.message}</p>
                )}
              </div>
            </div>

            <div className="flex items-center rounded-xl border border-gray-100 bg-gray-50 p-3">
              <CustomCheckBox
                size="lg"
                gapSize="lg"
                enabled={enabled}
                setEnabled={setEnabled}
                label="회사 대표자와 동일합니다"
              />
            </div>

            <div className="flex items-center rounded-xl border border-gray-100 bg-gray-50 p-3">
              <CustomCheckBox
                size="lg"
                gapSize="lg"
                enabled={isSendInvite}
                setEnabled={setIsSendInvite}
                customLabel={
                  <p className="flex gap-1">
                    <span className="font-bold text-blue-500">[가입 초대 알림톡 전송]</span>
                    입주사 등록 직후, 최고관리자에게 가입 초대 알림톡(카카오톡)을 전송합니다.
                    <Icon.Messenger variant="Bulk" size={20} color="#3B82F6" />
                  </p>
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg border border-gray-200">
        <div className="flex flex-col gap-1 border-b border-gray-200 px-6 py-4">
          <span className="text-xl font-semibold leading-8 text-gray-700">부가 정보</span>
          <span className="text-sm font-medium text-gray-500">부가적으로 입력하여 기록하는 항목입니다.</span>
        </div>
        <div className="flex flex-col gap-6 px-6 py-5">
          <div className="flex gap-4">
            <div className="flex flex-1 flex-col gap-1">
              <span className="text-sm font-semibold text-gray-700">입주형태</span>
              <div className="flex h-12 items-center">
                <CustomRadioGroup
                  data={MOVE_IN_TYPE}
                  getValue={moveInType}
                  setState={setMoveInType}
                  className="flex gap-5"
                />
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-1">
              <span className="text-sm font-semibold text-gray-700">입주기간</span>
              <CustomDatePicker startDate={startDate} endDate={endDate} setDateRange={setDateRange} />
            </div>
          </div>

          <div className="flex gap-4">
            <div className="flex flex-1 flex-col gap-1">
              <span className="text-sm font-semibold text-gray-700">사업자등록번호</span>
              <Input
                themeSize="md"
                placeholder="사업자번호를 입력해주세요"
                type="number"
                {...register("companyRegistrationNumber", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "사업자번호는 숫자만 입력해주세요.",
                  },
                })}
                onBlur={(e) => setValue("companyRegistrationNumber", e.target.value.trim())}
              />
              {errors.companyRegistrationNumber && (
                <p className="text-xs leading-normal text-red-500">{errors.companyRegistrationNumber.message}</p>
              )}
            </div>
            <div className="flex flex-1 flex-col gap-1">
              <span className="text-sm font-semibold text-gray-700">세금계산서 이메일</span>
              <Input
                themeSize="md"
                placeholder="세금계산서 발행용 이메일을 입력해주세요"
                type="email"
                {...register("taxEmail", {
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                    message: "이메일 형식으로 정확히 입력해주세요.",
                  },
                })}
                onBlur={(e) => setValue("taxEmail", e.target.value.trim())}
              />
              {errors.taxEmail && <p className="text-xs leading-normal text-red-500">{errors.taxEmail.message}</p>}
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-1">
            <span className="text-sm font-semibold text-gray-700">팩스번호</span>
            <Input
              themeSize="md"
              placeholder="팩스번호를 입력해주세요"
              type="fax"
              {...register("fax", {
                pattern: {
                  value: /^[0-9]+$/,
                  message: "팩스번호는 숫자만 입력해주세요.",
                },
              })}
              onBlur={(e) => setValue("fax", e.target.value.trim())}
            />
            {errors.fax && <p className="text-xs leading-normal text-red-500">{errors.fax.message}</p>}
          </div>

          <div className="flex flex-col gap-1">
            <span className="text-sm font-semibold text-gray-700">사업자등록증</span>
            <div className="flex flex-col divide-y divide-gray-200 rounded-md border border-gray-200">
              {businessFields.map((file, index) => (
                <div key={file.id} className="flex items-center justify-between p-3 pr-4">
                  <span className="flex gap-2">
                    <Icon.Paperclip size={20} color="#9CA3AF" />
                    <p className="text-sm font-medium leading-5 text-gray-900">{file.name}</p>
                  </span>
                  {auth === "edit" && (
                    <Icon.Trash
                      variant="Outline"
                      size={20}
                      color="#FCA5A5"
                      className="cursor-pointer"
                      onClick={() => removeBusinessFile(index)}
                    />
                  )}
                </div>
              ))}
              <div className="flex w-full items-center justify-between px-3 py-2">
                <Button
                  designSchema="ghostBlue"
                  iconElement={<IconPlus className="h-4 w-4" />}
                  size="sm"
                  buttonText="파일 업로드"
                  onClick={() => businessInputRef.current?.click()}
                  type="button"
                />
                <input
                  type="file"
                  multiple={true}
                  ref={businessInputRef}
                  className="hidden"
                  onChange={(e) => uploadFile(e, "business")}
                />
                <span className="text-sm leading-5 text-gray-400">파일 당 최대 5MB / 5개</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <span className="text-sm font-semibold text-gray-700">계약서</span>
            <div className="flex flex-col divide-y divide-gray-200 rounded-md border border-gray-200">
              {contractFields.map((file, index) => (
                <div key={file.id} className="flex items-center justify-between p-3 pr-4">
                  <span className="flex gap-2">
                    <Icon.ImportCurve size={20} color="#9CA3AF" />
                    <p className="text-sm font-medium leading-5 text-gray-900">{file.name}</p>
                  </span>

                  {auth === "edit" && (
                    <Icon.Trash
                      variant="Outline"
                      size={20}
                      color="#FCA5A5"
                      className="cursor-pointer"
                      onClick={() => removeContractFile(index)}
                    />
                  )}
                </div>
              ))}

              <div className="flex w-full items-center justify-between px-3 py-2">
                <Button
                  designSchema="ghostBlue"
                  iconElement={<IconPlus className="h-4 w-4" />}
                  size="sm"
                  buttonText="파일 업로드"
                  onClick={() => contractInputRef.current?.click()}
                  type="button"
                />
                <input
                  type="file"
                  multiple={true}
                  ref={contractInputRef}
                  className="hidden"
                  onChange={(e) => uploadFile(e, "contract")}
                />
                <span className="text-sm leading-5 text-gray-400">파일 당 최대 5MB / 5개</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
